import React, { Component, Fragment } from "react";
import BigCard from "../../app/utils/BigCard.jsx";
import PaymentForm from "../utils/PaymentForm.jsx";
import overlayFactory from "react-bootstrap-table2-overlay";
import BootstrapTable from "react-bootstrap-table-next";

const formatDate = (date) => {
  const d = new Date(date);
  const options = {
    timeZone: "America/Argentina/Buenos_Aires",
    hour12: false,
  };

  const formattedDate = d.toLocaleString("es-AR", options);
  const [datePart, timePart] = formattedDate.split(", ");
  const [day, month, year] = datePart.split("/");
  const [hours, minutes, seconds] = timePart.split(":");
  const paddedDay = day.padStart(2, "0");
  const paddedMonth = month.padStart(2, "0");
  return `${paddedDay}/${paddedMonth}/${year} ${hours}:${minutes}:${seconds}`;
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

class TableEmv extends Component {
  constructor(props) {
    super(props);
    this.columns = props.columns;
    this.path = props.path;
    this.history = props.history;
    this.state = {
      tableRows: [],
      loading: false,
    };
  }

  setTableRows = (rows) => {
    this.setState({ loading: true });
    const formattedRows = rows.transactions.map((row, index) => {
      return {
        ...row,
        date: formatDate(row.date),
        value: formatCurrency(row.value),
        key: index,
      };
    });

    this.setState({
      tableRows: { transactions: formattedRows },
      loading: false,
    });
  };
  render() {
    return (
      <Fragment>
        <BigCard>
          <PaymentForm setTableRows={this.setTableRows} />
        </BigCard>
        <BigCard>
          <div>
            <BootstrapTable
              data={this.state?.tableRows?.transactions || []}
              columns={this.columns}
              keyField="key"
              classes="table-hover"
              loading={this.state.loading}
              remote
              noDataIndication="La tabla está vacía"
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(18,139,252,0.8)",
                  }),
                },
              })}
            />
          </div>
        </BigCard>
      </Fragment>
    );
  }
}

export default TableEmv;
