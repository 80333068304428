import React, { Component } from "react";
import UserProfile from "../../app/shared/UserProfile";
import Error404 from "../../app/user-pages/Error404";
import TableEmv from "../../app/tables/TableEmv.jsx";

class Emv extends Component {
  constructor() {
    super();
    this.title = "EMV";
    this.friendlyPath = "emv";
    this.path = process.env.REACT_APP_EMV;
    this.userProfile = UserProfile.getInstance();
    // this.hasRenderPermission = this.userProfile.hasGeneralPermisson();
    this.hasEmvRole = this.userProfile.hasEmvRole(); // Role de prueba para el modulo EMV

    this.columns = [
      { dataField: "line", text: "Linea", isForTable: true },
      { dataField: "branch", text: "Ramal", isForTable: true },
      { dataField: "description", text: "Descripción", isForTable: true },
      { dataField: "value", text: "Tarifa", isForTable: true },
      { dataField: "date", text: "Fecha", isForTable: true },
    ];
  }

  render = () => {
    if (!this.hasEmvRole) return <Error404 />;
    else
      return (
        <TableEmv
          columns={this.columns}
          path={this.path}
          friendlyPath={this.friendlyPath}
          hasCreate={true}
          history={this.props.history}
        />
      );
  };
}

export default Emv;
