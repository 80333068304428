class UserProfile {
  static instance = null;
  token = "";
  userInfo = null;

  static getInstance() {
    if (UserProfile.instance === null) {
      UserProfile.instance = new UserProfile();
    }
    return UserProfile.instance;
  }

  getToken = () => this.token;

  setToken = (tokenP) => (this.token = tokenP);

  getUserInfo = () => this.userInfo;

  setUserInfo = (userInfoP) => (this.userInfo = userInfoP);

  getUserRoles = () => this.userInfo.roles;

  hasGeneralPermisson = () =>
    this.userInfo.roles?.includes("ROLE_TMI_INSPECTOR");

  hasInspectionShowPermisson = () =>
    this.userInfo.roles?.includes("ROLE_TMI_BE_INSPECTION_SHOW");

  hasInspectionCreatePermisson = () =>
    this.userInfo.roles?.includes("ROLE_TMI_BE_INSPECTION_CREATE");

  hasInspectorShowPermisson = () =>
    this.userInfo.roles?.includes("ROLE_TMI_BE_INSPECTOR_SHOW");

  hasInspectorCreatePermisson = () =>
    this.userInfo.roles?.includes("ROLE_TMI_BE_INSPECTOR_CREATE");

  hasInspectorReportPermisson = () =>
    this.userInfo.roles?.includes("ROLE_TMI_BE_INSPECTOR_REPORT");

  hasEmvRole = () => this.userInfo.roles?.includes("ROLE_TMI_EMV"); // Role de prueba para el modulo EMV
}
export default UserProfile;
