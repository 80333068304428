import React, { useEffect, useState } from "react";
import axios from "axios";
import UserProfile from "../shared/UserProfile";

const PaymentForm = ({ setTableRows }) => {
  const [captureContext, setCaptureContext] = useState(null);
  const [microform, setMicroform] = useState(null);

  const [showError, setShowError] = useState(false);

  const waitForCyberSource = () => {
    return new Promise((resolve, reject) => {
      if (window.CyberSource?.Flex) {
        resolve(window.CyberSource);
      } else reject(new Error("CyberSource no se cargó correctamente."));
    });
  };

  const emvToken = UserProfile.getInstance().token;
  useEffect(() => {
    const fetchCaptureContext = async () => {
      const config = {
        // url: "http://localhost:3002/emv-trips-api/generateContext", // local test
        url: window.globalConfig.apiEmvUrlGenerateContext,
        method: "post",
        headers: {
          Authorization: "Bearer " + emvToken,
        },
        data: {
          targetOrigins: [
            "https://www.controlsube.com.ar",
            // "http://localhost:3000",
            // "http://34.117.77.98",
            // "https://api-staging.nacionservicios.com.ar",
            // "https://api-testing.nacionservicios.com.ar",
            // "https://api-pre.nacionservicios.com.ar",
            // "https://api.nacionservicios.com.ar",
          ],
          allowedCardNetworks: [
            "VISA",
            "MASTERCARD",
            "AMEX",
            "DISCOVER",
            "JCB",
          ],
        },
      };

      try {
        const response = await axios(config);
        if (response.data?.captureContext) {
          setCaptureContext(response.data.captureContext);
        }
      } catch (error) {
        console.error("Error obteniendo Capture Context:", error);
      }
    };

    fetchCaptureContext();
  }, [emvToken]);

  // mostrar error en consola
  let isError = 0;

  useEffect(() => {
    if (!captureContext) {
      return;
    }

    // La URL de cybersource no se puede mover del codigo
    const scriptUrl =
      "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js";
    // No funciona
    // "https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js";

    const loadScript = () => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${scriptUrl}"]`)) {
          return resolve();
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => {
          const myStyle = {
            input: {
              fontSize: "14px",
              color: "#000",
            },
            ":focus": { color: "blue" },
            ":disabled": { cursor: "not-allowed" },
            valid: { color: "green" },
            invalid: { color: "red" },
          };
          const flex = new window.Flex(captureContext);
          const microformInstance = flex.microform({
            styles: myStyle,
          });
          const cardField = microformInstance.createField("number", {
            placeholder: "Número de Tarjeta",
          });
          cardField.load("#card-number-container");

          setMicroform(microformInstance);
          resolve();
        };
        script.onerror = () => {
          console.error("Error al cargar el script de CyberSource.");
          reject(new Error("Error al cargar el script de CyberSource"));
        };
        document.body.appendChild(script);
      });
    };

    loadScript()
      .then(waitForCyberSource)
      .then(() => {})
      .catch((error) => {
        if (isError > 0) {
          console.error("Error cargando script de CyberSource", error);
        } else isError++;
      });
  }, [captureContext, isError]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!microform) {
      console.error("Microform no está inicializado correctamente.");
      return;
    }

    try {
      const options = {};
      microform.createToken(options, async function (err, token) {
        if (err) {
          setShowError(true);
          console.error("Error create Token", err);
        } else {
          setShowError(false);

          const backendResponse = async () => {
            const config = {
              // url: "http://localhost:3002/emv-trips-api/processTransaction", // local test
              url: window.globalConfig.apiEmvUrlProcessTransaction,
              method: "post",
              headers: {
                Authorization: "Bearer " + emvToken,
              },
              data: {
                transientToken: token,
              },
            };
            return axios.post(config.url, config.data, {
              headers: config.headers,
            });
          };

          try {
            const response = await backendResponse();
            setTableRows(response.data);
          } catch (error) {
            console.error("Error en la llamada al backend:", error);
          }
        }
      });
    } catch (error) {
      console.error("Error generando token:", error);
    }
  };

  return (
    <div>
      <h2>Viajes realizados</h2>
      <form onSubmit={handleSubmit}>
        <p>Introduzca los 16 números de la tarjeta</p>
        <div id="card-number-container" className="form-control"></div>

        {showError && (
          <p style={{ color: "red" }}>Número de tarjeta inválido</p>
        )}

        <button type="submit" disabled={false}>
          Buscar
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;
